import React from 'react'
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Routes,
  Link
} from "react-router-dom";
import Navbar from './components/Navbar/Navbar';
import { About, Header, Work, Skills, Testimonial, Footer, Contact } from './container';
import './App.scss'

export const App = () => {
  return (
    <Router>
      <Routes>
          <Route path="/contact-ladla" element={<Contact />} />
          <Route path="/" element={<Home />} />
        </Routes>
    </Router>
    
  )
}

function Home() {
  return (
    <div className="app">
        <Navbar />
        <Header />
        <About />
        <Work />
        <Skills />
        <Testimonial />
        <Footer />
    </div>
  );
}

export default App