import React from "react";
import { BsTwitter, BsInstagram } from "react-icons/bs";
import { FaFacebookF, FaLinkedinIn } from "react-icons/fa";

const openLinkedIn = () => {
  const newWindow = window.open(
    "https://www.linkedin.com/in/mohammad-gayashuddin-529002185/",
    "_blank",
    "noopener,noreferrer"
  );
  if (newWindow) newWindow.opener = null;
};

const openTwitter = () => {
  const newWindow = window.open(
    "https://twitter.com/Mohamma29482095",
    "_blank",
    "noopener,noreferrer"
  );
  if (newWindow) newWindow.opener = null;
}

const openFacebook = () => {
  const newWindow = window.open(
    "https://www.facebook.com/ladla8602",
    "_blank",
    "noopener,noreferrer"
  );
  if (newWindow) newWindow.opener = null;
}

const openInstagram = () => {
  const newWindow = window.open(
    "https://instagram.com/md_gayashuddin",
    "_blank",
    "noopener,noreferrer"
  );
  if (newWindow) newWindow.opener = null;
}

const SocialMedia = ({className }) => (
  <div className={`app__social ${className}`}>
    <div>
      <FaLinkedinIn onClick={() => openLinkedIn()} />
    </div>
    <div>
      <BsTwitter onClick={() => openTwitter()} />
    </div>
    <div>
      <FaFacebookF onClick={() => openFacebook()} />
    </div>
    <div>
      <BsInstagram onClick={() => openInstagram()} />
    </div>
  </div>
);

export default SocialMedia;
