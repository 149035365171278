
import React, {useEffect, useState} from 'react'
import { motion } from "framer-motion";
import './Contact.scss'
import { AppWrap, MotionWrap } from "../../wrapper";
import { urlFor, client } from "../../client";

const Contact = () => {

  const [contacts, setContact] = useState([]);

  useEffect(() => {
    const query = '*[_type == "contact"]';

    client.fetch(query).then((data) => setContact(data));
  }, []);

  return (
    <>
      <h2 className="head-text">
        Contacts
      </h2>

      <div className="app__profiles">
      <div className="styled-table">
          <table className="table table-hover table-striped table-responsive">
            <thead>
              <tr>
                <th>Name</th>
                <th>Email</th>
                <th>Message</th>
                <th>Created At</th>
              </tr>
            </thead>
            <tbody>
            {contacts.map((contact, index) => (
            <tr key={contact+index} className="animated fadeIn">
                <td className="text-capitalize"><i className="glyphicon glyphicon-user"></i> {contact.name}</td>
                <td className="text-lowercase"><i className="glyphicon glyphicon-envelope"></i> {contact.email}</td>
                <td style={{overflow:"auto", "minHeight":"auto", "maxWidth": "300px"}}><i className="glyphicon glyphicon-earphone"></i> {contact.message}</td>
                <td><i className="glyphicon glyphicon-earphone"></i> { new Date(contact._createdAt).toDateString() }</td>
              </tr>
        ))}
            </tbody>
          </table>

        </div>
 
      </div>
    </>
  );
}

export default Contact;
